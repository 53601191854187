import React from "react";
import DetailAll from "../Components/DataAll";
const Data = () => {
  return (
    <div>
      <DetailAll />
    </div>
  );
};

export default Data;
