import React from "react";
import Router from "./config/Router";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
