import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { baseUrl } from "../utils/Constant";
import { toast } from "react-toastify";

const DataAll = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorInfo, setErrorInfo] = useState("");
  const [allData, setAllData] = useState([]);
  const [updatedIndices, setUpdatedIndices] = useState(new Set());
  const [update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);

  let getForm = () => {
    setLoading(true);
    const secret = prompt("Enter the secret key to continue:");

    if (!secret) {
      setLoading(false);
      setError(true);
      setErrorInfo("No secret key provided.");

      return;
    }

    const urlWithParams = `${baseUrl}/box-data-protected?secret=${encodeURIComponent(
      secret
    )}`;

    fetch(urlWithParams, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          setLoading(false);
          setAllData(data.allData.reverse());
          console.log("fetchedData: ", data.allData);
        } else {
          setError(true);

          console.log("fetchedData: ", data);
          setErrorInfo(`Error: ${data.msg || "Error fetching data"}`);
        }
      })
      .catch((e) => {
        console.log("fetchedData: ", e);
        setError(true);
        setErrorInfo("Something went wrong. Please reload the page.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Call the function
  useEffect(() => {
    getForm();
  }, []);

  function handleUpdate(userData, index) {
    const updatedUserData = {
      name: userData.userInfo.name,
      email: userData.userInfo.email,
      phoneNumber: userData.userInfo.phoneNumber,
      isChecked: userData.userInfo.isChecked,
    };

    if (
      !userData.userInfo.name.trim() ||
      !userData.userInfo.email.trim() ||
      !userData.userInfo.phoneNumber
    ) {
      return toast.info("Input validation failed, fields cannot be empty");
    }

    setUpdate(true);

    fetch(`${baseUrl}/update-user-info/${userData.boxNumber}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedUserData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          toast.success(data.msg);
          setUpdatedIndices(new Set([...updatedIndices, index]));
        } else {
          // Handle error, if any
          toast.error(`Error updating the data: ${data.msg}`);
        }
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating the data:", error);
        toast.error("Error updating the data");
      })
      .finally(() => {
        setUpdate(false);
      });
  }
  function handleDelete(userData, index) {
    setDelete(true);

    fetch(`${baseUrl}/update-delete-info/${userData.boxNumber}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          toast.success(data.msg);

          setAllData((prevData) => prevData.filter((_, i) => i !== index));
        } else {
          toast.error(`Error updating the data: ${data.msg}`);
        }
      })
      .catch((error) => {
        console.error("Error updating the data:", error);
        toast.error("Error updating the data");
      })
      .finally(() => {
        setDelete(false);
      });
  }
  useEffect(() => {
    // This will be triggered whenever allData changes
    // You can perform additional actions here if needed
  }, [allData]);
  return (
    <div className="p-4">
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <Spinner animation="border" role="status" variant="primary"></Spinner>
          <span className="ml-2 ms-2">Loading...</span>
        </div>
      ) : error ? (
        <div id="alertContainer" className="alert alert-danger">
          {errorInfo}
        </div>
      ) : !loading && allData ? (
        <div className="container" style={{ textAlign: "left" }}>
          <div className="row">
            {allData.map((userData, index) => (
              <div key={index} className="col-md-6 mb-4">
                <div className="card">
                  <div className="card-body">
                    <h3 className="card-title">Collection {index + 1}</h3>
                    <p className="card-title text-graay">
                      Box Number: {userData.boxNumber}
                    </p>
                    <div className="mb-3">
                      <label className="form-label">Name</label>
                      <input
                        value={userData.userInfo.name}
                        onChange={(e) =>
                          setAllData((prevData) => {
                            const newData = [...prevData];
                            newData[index].userInfo.name = e.target.value;
                            return newData;
                          })
                        }
                        type="text"
                        className="form-control"
                        placeholder="Enter name"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Email</label>
                      <input
                        value={userData.userInfo.email}
                        onChange={(e) =>
                          setAllData((prevData) => {
                            const newData = [...prevData];
                            newData[index].userInfo.email = e.target.value;
                            return newData;
                          })
                        }
                        type="email"
                        className="form-control"
                        placeholder="Enter email"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Phone Number</label>
                      <input
                        value={userData.userInfo.phoneNumber}
                        onChange={(e) =>
                          setAllData((prevData) => {
                            const newData = [...prevData];
                            newData[index].userInfo.phoneNumber =
                              e.target.value;
                            return newData;
                          })
                        }
                        type="tel"
                        className="form-control"
                        placeholder="Enter phone number"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-check-label me-2">
                        Keep Information Private
                      </label>
                      <input
                        type="checkbox"
                        checked={userData.userInfo.isChecked}
                        className="form-check-input"
                        onChange={(e) =>
                          setAllData((prevData) => {
                            const newData = [...prevData];
                            newData[index].userInfo.isChecked =
                              e.target.checked;
                            return newData;
                          })
                        }
                      />
                    </div>
                    <div>
                      <button
                        onClick={() => handleUpdate(userData, index)}
                        className="btn btn-success me-2"
                        disabled={update || updatedIndices.has(index)}
                      >
                        {updatedIndices.has(index) ? "Updated" : "Update"}
                      </button>
                      <button
                        disabled={Delete}
                        onClick={() => handleDelete(userData, index)}
                        className="btn btn-danger"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DataAll;
