import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button, Modal, Spinner } from "react-bootstrap";

import { toast } from "react-toastify";
import { baseUrl } from "../utils/Constant";

const BoxComponent = ({ boxNumber, isFilled, onBoxClick, bxName }) => {
  console.log(baseUrl);

  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [keepSecret, setKeepSecret] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailValidation, setEmailValidation] = useState("");

  const resetErrors = () => {
    setNameError("");
    setEmailError("");
    setPhoneNumberError("");
    setEmailValidation("");
  };

  const handleBoxClick = () => {
    if (isFilled) {
      const filledByName = bxName || "Someone";
      toast.info(
        `Box ${boxNumber} has already been pledged by ${filledByName}`
      );
    } else {
      setShowModal(true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setEmail("");
    setPhoneNumber("");
    setName("");
    setKeepSecret(false);
  };

  const handleSave = () => {
    if (!name || !email || !phoneNumber) {
      setNameError(!name ? "Name is required" : "");
      setEmailError(!email ? "Email is required" : "");
      setPhoneNumberError(!phoneNumber ? "Phone Number is required" : "");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailValidation("Please enter a valid email address");
      return;
    }

    resetErrors();

    setLoading(true);

    axios
      .post(`${baseUrl}/submit-info`, {
        name,
        email,
        phoneNumber,
        keepSecret,
        boxNumber,
      })
      .then((response) => {
        toast.success(response.data.msg);
        window.location.href =
          "https://masjidbox.com/donations/scunthorpe-islamic-centre/new-masjid-project-1";

        handleClose();
      })
      .catch((error) => {
        console.error("Error submitting information:", error);
        toast.error("Error submitting information. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div
        className={`grid-item ${isFilled ? " pledged" : "pledged-filled"}`}
        data-box-number={boxNumber}
        onClick={handleBoxClick}
      >
        {boxNumber}
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Your Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enter your details for Box {boxNumber} by pledging £1000</p>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  resetErrors();
                }}
                isInvalid={!!nameError}
              />
              <Form.Control.Feedback type="invalid">
                {nameError}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  resetErrors();
                }}
                isInvalid={!!emailError || !!emailValidation}
              />
              <Form.Control.Feedback type="invalid">
                {emailError || emailValidation}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="number"
                onKeyDown={(e) =>
                  [
                    "ArrowUp",
                    "ArrowDown",
                    "e",
                    "E",
                    "+",
                    "-",
                    "*",
                    "",
                  ].includes(e.key) && e.preventDefault()
                }
                placeholder="Enter your phone number"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  resetErrors();
                }}
                isInvalid={!!phoneNumberError}
              />
              <Form.Control.Feedback type="invalid">
                {phoneNumberError}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formKeepSecret">
              <Form.Check
                type="checkbox"
                label="Keep information secret"
                checked={keepSecret}
                onChange={(e) => setKeepSecret(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Boxes = () => {
  const [boxStatus, setBoxStatus] = useState(
    Array.from({ length: 500 }, () => false)
  );
  const [activeBox, setActiveBox] = useState(null);
  const [boxData, setBoxData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${baseUrl}/box-data`)
      .then((response) => {
        setBoxData(response.data.data);
        setLoading(false);

        const newBoxStatus = Array.from({ length: 459 }, () => false);
        response.data.data.forEach((box) => {
          newBoxStatus[box.boxNumber - 1] = true;
        });
        setBoxStatus(newBoxStatus);
      })
      .catch((error) => {
        console.error("Error fetching box data:", error);
        toast.error("Error fetching box data. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleBoxClick = (boxIndex) => {
    setActiveBox(boxIndex);
    setBoxStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[boxIndex] = true;
      return newStatus;
    });
  };

  return (
    <main>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <Spinner animation="border" role="status" variant="primary"></Spinner>
          <span className="ml-2 ms-2">Loading...</span>
        </div>
      ) : (
        <div className="grid-container" id="grid-container">
          {boxStatus.map((isFilled, index) => {
            const currentBox = boxData.find(
              (box) => box.boxNumber === index + 1
            );

            let bxName = null;

            if (
              isFilled &&
              currentBox?.userInfo?.isChecked !== undefined &&
              currentBox?.userInfo?.isChecked
            ) {
              bxName = null;
            } else {
              bxName = currentBox?.userInfo.name || null;
            }

            console.log(`Box ${index + 1} name:`, bxName);

            return (
              <BoxComponent
                key={index}
                boxNumber={index + 1}
                isFilled={isFilled}
                onBoxClick={() => handleBoxClick(index)}
                bxName={bxName}
              />
            );
          })}
        </div>
      )}

      <Modal show={activeBox !== null} onHide={() => setActiveBox(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Your Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BoxComponent
            boxNumber={activeBox + 1}
            isFilled={false}
            onBoxClick={() => setActiveBox(null)}
          />
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default Boxes;
