import React from "react";

const Footer = () => {
  return (
    <div className="ans">
      <p>
        Scunthorpe Islamic Centre, 4 Parkinson Avenue, Scunthrope, DN15 7JX is a
        UK Registered Charity (1008763) - Sort Code: 60-11-33 | Account:
        22813012
      </p>
    </div>
  );
};

export default Footer;
