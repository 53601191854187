import logo from "../assets/logo.jpeg";
import React from "react";

const NavBar = () => {
  return (
    <div className="main-nav">
      <img src={logo} alt="" />
      <h1 className="tnt">
        New Masjid Project | Donation Wall | Sadqah Jaariyah <br />1 Square =
        €1,000 = A Palace in Jannah, Insha'Allah!
      </h1>
      <marquee behavior="smooth" direction="" className="mara">
        {" "}
        New Masjid Project | Donation Wall | Sadqah Jaariyah 1 Square = €1,000 =
        A Palace in Jannah, Insha'Allah!
      </marquee>
      <img src={logo} alt="" />
    </div>
  );
};

export default NavBar;
